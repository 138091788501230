<template>
  <v-container>
    <v-app-bar fixed flat style="margin-top: 59px" elevate-on-scroll>
      <v-spacer></v-spacer>
      <v-btn
        color="var(--main)"
        dark
        small
        @click="NewCouponSelecterDialog = true"
      >
        <v-icon>mdi-plus</v-icon>
        New Coupon
      </v-btn>
      <v-menu offset-y bottom transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="white"
            v-bind="attrs"
            v-on="on"
            small
            class="text-secondary"
          >
            <v-icon>mdi-filter-outline</v-icon>
            Filter
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-subtitle>Value: High to Low</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>Value: Low to High</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>Expiry:Soon to Later</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-subtitle>Expiry: Later to Soon</v-list-item-subtitle>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <div class="container" style="margin-top: 50px">
      <div class="row">
        <div
          class="col-md-4 col-lg-4 col-sm-12"
          v-for="coupon in Coupons"
          :key="coupon.id"
        >
          <v-card color="var(--main)" dark ripple @click="openCoupon(coupon)">
            <v-card-actions class="position-absolute w-100 bg-transparent">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-card-actions>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  {{ coupon.retailer }}</v-card-title
                >
                <v-card-subtitle class="mb-0 pb-0">
                  <v-chip
                    small
                    :color="
                      isCouponAvailable(coupon)
                        ? 'primary'
                        : coupon.used == true
                        ? 'var(--warning)'
                        : 'var(--danger)'
                    "
                  >
                    {{
                      isCouponAvailable(coupon) == true
                        ? "Available"
                        : coupon.used
                        ? "Used"
                        : "Expired/Used"
                    }}
                  </v-chip>
                </v-card-subtitle>
                <v-card-text class="d-flex flex-column">
                  <div class="d-inline-flex p-0 m-0">
                    <v-icon small> mdi-clock-remove-outline </v-icon>
                    <v-spacer></v-spacer>
                    <span class="font-weight-bold text-start">
                      {{ prepedDate(coupon.expiryDate) }}
                    </span>
                  </div>
                  <div class="d-inline-flex p-0 m-0">
                    <v-icon> mdi-cash </v-icon>
                    <v-spacer></v-spacer>
                    <span class="text-h6 text-start">
                      {{ coupon.value }}
                    </span>
                  </div>
                </v-card-text>
              </div>
              <v-avatar class="mr-3 mt-8" size="100" color="var(--accent)">
                <v-img
                  v-if="coupon.type == 'qr_code'"
                  :src="coupon.mediaLink"
                ></v-img>
                <v-icon v-if="coupon.type != 'qr_code'" x-large>
                  {{ coupon.type == "Pin" ? "mdi-dialpad" : "mdi-link" }}
                </v-icon>
              </v-avatar>
            </div>
            <v-card-actions class="p-0 m-0">
              <v-expansion-panels class="bg-transparent p-0 m-0">
                <v-expansion-panel class="p-0 m-0">
                  <v-expansion-panel-header
                    class="bg-transparent py-1 m-0"
                    small
                  >
                    <span>
                      <v-icon>mdi-ticket-percent-outline</v-icon>
                      Details
                    </span>
                    <template v-slot:actions>
                      <v-icon color="primary">$expand</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="p-0 m-0">
                    <v-list class="p-0">
                      <v-list-item>
                        <v-list-item-title>Category</v-list-item-title>
                        <v-list-item-icon>
                          <v-icon>
                            {{
                              coupon.type == "Pin"
                                ? "mdi-dialpad"
                                : coupon.type == "Link"
                                ? "mdi-link"
                                : "mdi-qrcode"
                            }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="text-right">{{
                          coupon.type.toUpperCase()
                        }}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Expiry</v-list-item-title>
                        <v-list-item-icon>
                          <v-icon> mdi-clock-remove-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="text-right"
                          >{{ prepedDate(coupon.expiryDate) }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Notify</v-list-item-title>
                        <v-list-item-icon>
                          <v-icon> mdi-bell-ring-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="text-right"
                          >{{ prepedDate(coupon.notificationDate) }}
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item ripple>
                        <v-list-item-title>
                          {{
                            coupon.type == "qr_code"
                              ? "Code"
                              : coupon.type == "Link"
                              ? "Redeem"
                              : "Show"
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            coupon.type != "qr_code"
                              ? coupon.content.toString().substring(0, 5)
                              : coupon.code.toString().substring(0, 5)
                          }}
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                    <v-list-item class="p-0">
                      <v-list-item-content class="p-0">
                        <v-card-actions class="text-center p-0">
                          <v-spacer></v-spacer>
                          <v-btn-toggle>
                            <v-btn
                              x-small
                              color="primary"
                              v-if="isCouponAvailable(coupon) == true"
                              @click="redeemCouponDialog = true"
                              >Redeem
                            </v-btn>
                            <v-btn
                              x-small
                              color="var(--danger)"
                              @click.capture="deleteDialog = true"
                              >Delete</v-btn
                            >
                            <v-btn
                              x-small
                              color="var(--warning)"
                              class="text-dark"
                              @click="rescheduleDialog = true"
                              v-if="isCouponAvailable(coupon) == true"
                            >
                              Reschedule
                            </v-btn>
                          </v-btn-toggle>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <!--Reschedule Coupon Modals-->
    <v-row justify="center">
      <v-dialog v-model="redeemCouponDialog" width="320px">
        <v-card v-if="focusCoupon" class="p-0">
          <v-card-title>
            Redeem Coupon
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="red"
              class="text-light"
              x-small
              fab
              @click="redeemCouponDialog = false"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-center p-1">
            <v-img
              width="320px"
              height="320px"
              :src="focusCoupon.mediaLink"
              v-if="focusCoupon.type == 'qr_code'"
            />
            <h4 v-if="focusCoupon.type == 'Link'">
              <a :href="focusCoupon.content" target="_blank"> Click to Open </a>
            </h4>
            <h4 v-if="focusCoupon.type == 'Pin'">{{ focusCoupon.content }}</h4>
            <hr />
            <v-btn
              block
              @click="doRedeemCoupon"
              color="deep-orange"
              class="text-light"
            >
              Redeem
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="rescheduleDialog" width="320px">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step color="var(--main)" :complete="e1 > 1" step="1">
              Date
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="var(--main)" :complete="e1 > 2" step="2">
              Time
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" color="var(--main)">
              Confirm
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="text-center" color="grey lighten-1">
                <v-date-picker
                  v-model="date"
                  color="var(--main)"
                ></v-date-picker>
              </v-card>
              <v-btn color="var(--main)" @click="e1 = 2" class="text-light">
                Continue
              </v-btn>
              <v-btn
                text
                @click="
                  () => {
                    rescheduleDialog = false;
                    focusCoupon = null;
                    e1 = 1;
                  }
                "
              >
                Cancel
              </v-btn>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="text-center" color="grey lighten-1">
                <v-time-picker
                  v-model="time"
                  color="var(--main)"
                ></v-time-picker>
              </v-card>
              <v-btn color="var(--main)" @click="e1 = 3" class="text-light">
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12 text-center" color="grey lighten-1">
                <v-card-text class="text-center">
                  <h4>Press continue to commit changes</h4>
                </v-card-text>
              </v-card>
              <v-btn
                color="var(--main)"
                @click="setRescheduleDate"
                class="text-light"
              >
                Continue
              </v-btn>
              <v-btn
                text
                @click="
                  () => {
                    rescheduleDialog = false;
                    focusCoupon = null;
                    e1 = 1;
                  }
                "
                class="text-light bg-danger"
              >
                Cancel
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-dialog>

      <v-dialog v-model="deleteDialog" max-width="310px">
        <v-card>
          <v-card-title class="text-h5">
            <v-icon color="red"> mdi-delete-forever-outline </v-icon>
            Confirm Delete
          </v-card-title>

          <v-card-text v-if="focusCoupon">
            <v-list-item>
              <v-list-item-title> Retailer </v-list-item-title>
              <v-list-item-subtitle>
                {{ focusCoupon.retailer.toUpperCase() }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title> Type </v-list-item-title>
              <v-list-item-subtitle>
                {{ focusCoupon.type }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="focusCoupon.used == false">
              <v-list-item-title> Value </v-list-item-title>
              <v-list-item-subtitle>
                {{ focusCoupon.value }}
              </v-list-item-subtitle>
            </v-list-item>
            <p>
              {{
                focusCoupon.used == false
                  ? "You will lose " + focusCoupon.value + " in value."
                  : null
              }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="deep-orange" text @click="deleteDialog = false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="
                () => {
                  deleteDialog = false;
                  doDeleteCoupon();
                }
              "
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Add New Coupon Dialogs -->
      <!-- Select the type of Coupon -->
      <v-dialog v-model="NewCouponSelecterDialog" width="320px">
        <v-card>
          <v-card-title class="text-h5">
            Coupon Type
            <v-spacer></v-spacer>
            <v-icon color="var(--main)" large>
              mdi-ticket-percent-outline
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-list-item-group>
              <v-list-item
                @click="
                  () => {
                    NewCouponSelecterDialog = false;
                    selectedCouponType = 'Link';
                    newCoupon.type = 'Link';
                    TextCouponDialog = true;
                  }
                "
              >
                <v-list-item-avatar>
                  <v-icon large color="var(--main)"> mdi-link </v-icon>
                </v-list-item-avatar>
                <v-list-item-title> Link </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  () => {
                    NewCouponSelecterDialog = false;
                    selectedCouponType = 'qr_code';
                    newCoupon.type = 'qr_code';
                    GraphicCouponDialog = true;
                  }
                "
              >
                <v-list-item-avatar>
                  <v-icon large color="var(--main)"> mdi-qrcode </v-icon>
                </v-list-item-avatar>
                <v-list-item-title> QR Code </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="
                  () => {
                    NewCouponSelecterDialog = false;
                    selectedCouponType = 'Pin';
                    newCoupon.type = 'Pin';
                    TextCouponDialog = true;
                  }
                "
              >
                <v-list-item-avatar>
                  <v-icon large color="var(--main)"> mdi-dialpad </v-icon>
                </v-list-item-avatar>
                <v-list-item-title> Pin </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-0 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="red"
              @click="NewCouponSelecterDialog = false"
            >
              Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- TextCoupon Dialog -->
      <v-dialog v-model="TextCouponDialog" width="310px">
        <v-card>
          <v-card-title> {{ selectedCouponType }} Coupon </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="doAddNewCoupon">
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      color="var(--main)"
                      prepend-inner-icon="mdi-store-24-hour"
                      label="Retailer"
                      required
                      flat
                      v-model="newCoupon.retailer"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      color="var(--main)"
                      :label="selectedCouponType"
                      v-model="newCouponOptionals.content"
                      hint=""
                      :prepend-inner-icon="
                        selectedCouponType == 'Pin' ? 'mdi-dialpad' : 'mdi-link'
                      "
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-menu offset-y bottom transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="var(--main)"
                          v-model="newCoupon.expiryDate"
                          label="Expiry Date:"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="newCoupon.expiryDate"
                        header-color="var(--main)"
                        scrollable
                        color="var(--main)"
                        required
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary"> Cancel </v-btn>
                        <v-btn text color="primary"> OK </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    Notification.
                    <v-row>
                      <v-col md="6" sm="6">
                        <v-menu offset-y bottom transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newCoupon.notificationDate"
                              label="Date:"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              color="var(--main)"
                              required
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="newCoupon.notificationDate"
                            header-color="var(--main)"
                            color="var(--main)"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary"> Cancel </v-btn>
                            <v-btn text color="primary"> OK </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col md="6" sm="6">
                        <v-menu offset-y bottom transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newCoupon.notificationTime"
                              label="Time:"
                              prepend-inner-icon="mdi-watch-vibrate"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              color="var(--main)"
                              required
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-model="newCoupon.notificationTime"
                            header-color="var(--main)"
                            color="var(--main)"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary"> Cancel </v-btn>
                            <v-btn text color="primary"> OK </v-btn>
                          </v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Value"
                      v-model="newCoupon.value"
                      prepend-inner-icon="mdi-ticket-percent-outline"
                      type="number"
                      required
                      color="var(--main)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn type="reset" text color="var(--secondary)">
                  Reset
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  type="reset"
                  text
                  color="var(--danger)"
                  @click="
                    () => {
                      TextCouponDialog = false;
                    }
                  "
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn type="submit" text color="var(--main)"> Add </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- QR Code coupon Dialog -->
      <v-dialog v-model="GraphicCouponDialog" width="320px">
        <v-card class="p-0">
          <v-card-title> QR CODE Coupon </v-card-title>
          <v-card-text class="p-0">
            <v-form @submit.prevent="DoUploadGraphicCoupon">
              <v-row no-gutters dense>
                <v-col cols="12">
                  <cropper
                    class="cropper"
                    v-show="newCouponOptionals.qr_codeImage"
                    :src="newCouponOptionals.qr_codeImage"
                    ref="cropper"
                    v-model="newCouponOptionals.qr_codeImage"
                    @change="
                      (data) => {
                        imageCropper = data;
                        change();
                      }
                    "
                  />
                  <input
                    v-if="newCouponOptionals.qr_codeImage == null"
                    ref="FileInput"
                    type="file"
                    @change="imageSelect"
                    accept="image/*"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    color="var(--main)"
                    prepend-inner-icon="mdi-store-24-hour"
                    label="Retailer"
                    required
                    flat
                    v-model="newCoupon.retailer"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    color="var(--main)"
                    label="Code"
                    v-model="newCouponOptionals.code"
                    hint=""
                    prepend-inner-icon="mdi-qrcode"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu offset-y bottom transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        color="var(--main)"
                        v-model="newCoupon.expiryDate"
                        label="Expiry Date:"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="newCoupon.expiryDate"
                      header-color="var(--main)"
                      scrollable
                      color="var(--main)"
                      required
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary"> Cancel </v-btn>
                      <v-btn text color="primary"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  Notification.
                  <v-row>
                    <v-col md="6" sm="6">
                      <v-menu offset-y bottom transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newCoupon.notificationDate"
                            label="Date:"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            color="var(--main)"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="newCoupon.notificationDate"
                          header-color="var(--main)"
                          color="var(--main)"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary"> Cancel </v-btn>
                          <v-btn text color="primary"> OK </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="6" sm="6">
                      <v-menu offset-y bottom transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="newCoupon.notificationTime"
                            label="Time:"
                            prepend-inner-icon="mdi-watch-vibrate"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            color="var(--main)"
                            required
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="newCoupon.notificationTime"
                          header-color="var(--main)"
                          color="var(--main)"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary"> Cancel </v-btn>
                          <v-btn text color="primary"> OK </v-btn>
                        </v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Value"
                    v-model="newCoupon.value"
                    prepend-inner-icon="mdi-ticket-percent-outline"
                    type="number"
                    required
                    color="var(--main)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn type="reset" text color="var(--secondary)">
                  Reset
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  type="reset"
                  text
                  color="var(--danger)"
                  @click="
                    () => {
                      GraphicCouponDialog = false;
                    }
                  "
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn type="submit" text color="var(--main)"> Add </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { uuid } from "vue-uuid";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "Coupons",
  components: {
    Cropper,
  },
  data() {
    return {
      Coupons: null,
      focusCoupon: null,
      couponDialog: false,
      deleteDialog: false,
      date: null,
      time: null,
      rescheduleDialog: false,
      e1: 1,
      NewCouponSelecterDialog: false,
      TextCouponDialog: false,
      GraphicCouponDialog: false,
      selectedCouponType: null,
      redeemCouponDialog: false,
      newCoupon: {
        id: null,
        retailer: null,
        type: null,
        value: null,
        expiryDate: null,
        notificationDate: null,
        notificationTime: null,
        owner: null,
        used: false,
      },
      newCouponOptionals: {
        code: null,
        content: null,
        qr_codeImage: null,
        mediaLink: null,
      },
      imageCropper: {},
    };
  },
  methods: {
    ...mapActions([
      "LoadCoupons",
      "RescheduleCoupon",
      "DeleteCoupon",
      "AddTextCoupon",
      "AddGraphicCoupon",
    ]),
    // Star Select and Crop Image
    change() {
      this.$refs.cropper.getResult().canvas.toBlob((blob) => {
        console.log(blob);
        this.newCouponOptionals.mediaLink = blob;
        return blob;
      });
    },
    DoUploadGraphicCoupon() {
      this.cropedImage = this.$refs.cropper.getResult().canvas.toDataURL();
      this.$refs.cropper.getResult().canvas.toBlob((blob) => {
        this.newCouponOptionals.mediaLink = blob;
        return blob;
      });
      let coupon = {
        ...this.newCoupon,
        dateAdded:
          moment().utc().toDate().toISOString().split("T")[0].toString() +
          " " +
          moment().utc().toDate().toISOString().split("T")[1].substring(0, 8),
      };
      coupon["code"] = this.newCouponOptionals.code;
      coupon["mediaLink"] = this.newCouponOptionals.mediaLink;
      let notificationDate =
        coupon.notificationDate + " " + coupon.notificationTime;
      let expiryDate = coupon.expiryDate;
      delete coupon["notificationTime"];
      coupon["notificationDate"] = notificationDate;
      coupon.owner = this.uid;
      coupon.expiryDate = expiryDate.toString() + " " + "23:59:59";
      coupon.id = uuid.v4().toString();
      coupon.value = parseFloat(this.newCoupon.value);
      this.AddGraphicCoupon(coupon).then(() => {
        this.coupon = {};
        this.GraphicCouponDialog = false;
      });
    },
    imageSelect(e) {
      if (typeof FileReader === "function") {
        let tmppath = (window.URL || window.webkitURL).createObjectURL(
          e.target.files[0]
        );
        this.newCouponOptionals.qr_codeImage = tmppath;
      }
    },
    // End Select and Crop Image
    setCoupons() {
      this.Coupons = this.storedCoupons;
    },
    prepedDate(dateString) {
      let d = moment(dateString).format("ll");
      return d;
    },
    isCouponAvailable(coupon) {
      const today = new Date();
      const expiry = moment(coupon.expiryDate).toDate();
      let life = moment(expiry.toString()).isSameOrAfter(today.toString());
      return life;
    },
    openCoupon(coupon) {
      this.focusCoupon = coupon;
      this.couponDialog = !this.couponDialog;
    },
    setRescheduleDate() {
      const datetime = `${this.date} ${this.time}`;
      const couponType = this.focusCoupon.type;
      const payload = {
        uid: this.uid,
        type: couponType,
        date: datetime,
        id: this.focusCoupon.id,
      };
      this.RescheduleCoupon(payload);
      this.rescheduleDialog = !this.rescheduleDialog;
    },
    doRedeemCoupon() {
      console.log(this.focusCoupon);
    },
    // Delete Coupon
    doDeleteCoupon() {
      const couponType = this.focusCoupon.type;
      const payload = {
        uid: this.uid,
        type: couponType,
        id: this.focusCoupon.id,
      };
      this.focusCoupon.type == "qr_code"
        ? (payload["mediaLink"] = this.focusCoupon.mediaLink)
        : null;
      this.DeleteCoupon(payload).then(() => {
        this.setCoupons();
        this.rescheduleDialog = false;
      });
    },
    // Adding a new Coupon
    doAddNewCoupon() {
      let coupon = {
        ...this.newCoupon,
        dateAdded:
          moment().utc().toDate().toISOString().split("T")[0].toString() +
          " " +
          moment().utc().toDate().toISOString().split("T")[1].substring(0, 8),
      };
      if (this.selectedCouponType == "qr_code") {
        coupon["code"] = this.newCouponOptionals.code;
        coupon["mediaLink"] = this.newCouponOptionals.mediaLink;
      } else {
        coupon["content"] = this.newCouponOptionals.content;
      }
      let notificationDate =
        coupon.notificationDate + " " + coupon.notificationTime;
      let expiryDate = coupon.expiryDate;
      delete coupon["notificationTime"];
      coupon["notificationDate"] = notificationDate;
      coupon.owner = this.uid;
      coupon.expiryDate = expiryDate.toString() + " " + "23:59:59";
      coupon.id = uuid.v4().toString();
      coupon.value = parseFloat(this.newCoupon.value);
      this.AddTextCoupon(coupon).then(() => {
        this.newCoupon = {};
        this.TextCouponDialog = false;
      });
    },
  },
  created() {
    this.LoadCoupons(this.uid);
    this.setCoupons();
  },
  computed: {
    ...mapGetters({
      uid: "getUid",
      storedCoupons: "getCoupons",
      couponTypes: "getCouponTypes",
    }),
  },
};
</script>
<style scoped>
:root {
  --main: #f06d34;
  --accent: #222832;
}
</style>
